.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  margin: 0;
  padding: 1.5em 1em;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/tsinghua.jpg);
  background-size: cover;
  background-position: center center;
  color: white;
  font-size: 2em;
}

.footnote {
  position: fixed;
  right: 1em;
  bottom: 0.75em;
  color: grey;
  font-size: 1em;
  text-align: right;
}

.footnote p {
  margin-bottom: 0;
}

#ep-image, #academic-image {
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: 200px;
  height: 100%;
}

#ep-image {
  background-image: url(./img/coronavirus.jpg);
}

#academic-image {
  background-image: url(./img/technology.jpg);
}

.column {
  margin: 3em 2em;
}

.item-card {
  text-align: left;
  margin-top: .25em;
}

.item-card .title,.author {
  display: inline;
}

.title {
  font-size: 1.35em;
  margin-right: 1em;
  color: #222222;
}

.author {
  color: #888888;
}

.bar {
  color: black;
  font-size: 1.5em;
  padding-bottom: 0.75em;
  padding-top: 1.75em;
}

@media (min-width:768px) {
  .content {
    padding: 0em 3em;
  }
  .column {
    margin-top: 4em;
    margin-bottom: 4em;
  }
  #ep-image, #academic-image {
    margin: 0em 3em;
    width: auto;
  }
  .header {
    font-size: 3.5em;
    padding: 1.5em 1em;
  }
  .bar {
    font-size: 2.25em;
  }
  .item-card {
    margin-left: 1em;
    margin-right: 1em;
  }
  .nav-btn {
    width: 135px;
  }
  .intro-section {
    font-size: 1.5em;
  }
}

@media (max-width:768px) {
  .nav-btn {
    width: 50px;
  }
  .intro-section {
    font-size: 1.25em;
  }
}

.separator {
  margin: 0em 3em;
  opacity: 0.5;
}

.title a,a:link,a:hover {
  text-decoration: underline;
  color: inherit;
}

.title:visited {
  text-decoration: inherit;
  color: inherit;
}

.main-body {
  max-width: 1600px;
  margin: 0 auto;
}

.cover-img {
  width: 100%;
  height: auto;
}

.cover-inner {
  width: 100%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}

.cover-container {
  margin: 10px;
  width: calc(100% - 20px) !important;
  height: auto;
}

.card-wrapper {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
}

.card-wrapper-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  background: linear-gradient(#ffffff00, #ffffffff);
  height: 40px;
}

.ant-card {
  height: 100%;
  overflow: hidden;
}

.card-meta-title {
  margin: 0 auto;
  font-size: 18px;
  white-space: pre-wrap;
  width: 100%;
}

.card-meta-title:hover {
  text-decoration: underline;
  cursor: pointer;

}

.card-meta-author {
  font-size: 14px;
  font-weight: 450;
  color: darkgrey;
}

.card-meta-description {
  white-space: pre-wrap;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}
.card-meta-description:hover {
  cursor: pointer;
  color: #555555;
}

.nav {
  position: fixed;
  right: 0;
  top: 20%;
}

.nav-btn {
  display: block;
  padding: 5px;
  font-size: 15px;
  border-width: 0px;
  border-radius: 15px 0 0 15px;
  border-style: solid;
  margin-top: 5px;
  font-weight: 500;
  color: #444444;
  transition: color .1s;
}

.nav-btn:hover {
  cursor: pointer;
  color: black;
}

.ant-col {
  margin-top: 10px;
  margin-bottom: 10px;
}

.working-badge {
  color: darkgreen;
}

.stopped-badge {
  color: darkgoldenrod;
}

.unknown-badge {
  color: grey;
}

.meta-header {
  width: 100%;
  vertical-align: top;
  opacity: 0.7;
  font-size: 0.25em;
  text-align: right;
  position: relative;
}

.working-badge, .stopped-badge, .unknown-badge {
  display: inline-block;
}

.card-meta-access-count {
  display: inline-block;
  position: absolute;
  left: 0;
  color: gray;
}

.intro-section {
  max-width: 768px;
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: left;
}

.intro-section a {
  text-decoration: underline;
  color: black;
  transition: color .25s;
}

.intro-section a:hover {
  text-decoration: underline;
  color: blue;
}

.year-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.year-item {
  font-size: 1.5em;
  margin: 0 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.year-item:hover {
  background-color: #f0f0f0;
}

.year-item.active {
  font-weight: bold;
  background-color: #e6f7ff;
}